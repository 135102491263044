import { useEffect } from "react";
import { http } from "./api";
import { useRetryUntilResolved } from "./hooks";
import { Error, Spinner } from "./components";
import { LoadingProvider } from "./context";

import App from "./App";

export default function Root() {
    const apiKey = new URLSearchParams(window.location.search).get("apiKey");

    const resolved = useRetryUntilResolved(() => typeof posContext !== "undefined" && http.initialized);

    useEffect(() => {
        if (apiKey && !http.initialized) {
            http.initialize(apiKey);
        }
    }, [apiKey]);

    if (!apiKey) {
        return (
            <Error
                message={
                    "An api key was not provided. Please check the web extension configuration in the Lightspeed (K-Series) dashboard."
                }
            />
        );
    }

    return resolved ? (
        <LoadingProvider>
            <App apiKey={apiKey} />
        </LoadingProvider>
    ) : (
        <div className="flex h-screen items-center justify-center text-core-gray-500">
            <Spinner className="h-[48px] w-[48px]" />
        </div>
    );
}
