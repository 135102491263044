import { useCallback, useEffect, useState } from "react";
import { FacingMode } from "@leatcom/portal-sessions";
import * as Sentry from "@sentry/react";
import { getContact, getIntegrationConfiguration, getLocationConfiguration } from "./api";
import {useLoading, WebExtensionProvider} from "./context";
import { GetCurrentAccountData, IntegrationConfiguration, LSKDiscount/*, LSKItem*/, Response } from "./types";
import { Router } from "./routes";

import LoadingOverlay from 'react-loading-overlay-ts';

type AppProps = {
    apiKey: string,
}

export default function App({ apiKey }: AppProps) {
    const [configuration, setConfiguration] = useState<IntegrationConfiguration>({
        facing_mode: FacingMode.USER,
        business_profile_uuid: "",
    });
    // const [items, setItems] = useState<Array<LSKItem>>([]);
    const [discounts, setDiscounts] = useState<Array<LSKDiscount>>([]);
    const [contactUuid, setContactUuid] = useState<string>();

    const { loading } = useLoading();

    const setup = useCallback(async () => {

        window.pos_getCurrentAccount(async (response: Response<GetCurrentAccountData, any>) => {
            if (response.data.consumer && response.data.consumer.email) {
                const { data } = await getContact(response.data.consumer.email);
                setContactUuid(data.uuid);
            }
        });

        try {
            const { data: configuration } = await getIntegrationConfiguration(posContext.locationId);
            setConfiguration(configuration);
        } catch (e) {
            Sentry.captureException(e);
        }

        try {
            const { data } = await getLocationConfiguration(posContext.locationId);
            // setItems(data.items);
            setDiscounts(data.discounts);
        } catch (e) {
            Sentry.captureException(e);
        }

    }, []);

    useEffect(() => {
        (async () => await setup())();
    }, [setup]);

    return (
        <WebExtensionProvider apiKey={apiKey} configuration={configuration} /*items={items}*/ discounts={discounts} contactUuid={contactUuid}>
            <LoadingOverlay
                active={loading}
                spinner
                text='Loading, do not close session...'
            >
                <Router />
            </LoadingOverlay>
        </WebExtensionProvider>
    );
}


